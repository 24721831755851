class DataBehaviorCollector {
  constructor(container) {
    this.container = container

    const self = this;

    const obj = {};

    const leave_tab_key = "leave_tab";

    setInterval(() => {
      let val;
      const old_leave_tab_value = obj[leave_tab_key];

      if (!document.hidden) {
        obj[leave_tab_key] = false;

        val = Number($("input[name='application[client_behavior_attributes][time_spent_on_the_questionnaire]']").val());

        val += 1;

        return $("input[name='application[client_behavior_attributes][time_spent_on_the_questionnaire]']").val(val);
      } else {
        obj[leave_tab_key] = true;

        if (old_leave_tab_value !== obj[leave_tab_key]) {
          let number = Number($("input[name='application[client_behavior_attributes][count_of_leave]']").val()) || 0;

          number += 1;

          return $("input[name='application[client_behavior_attributes][count_of_leave]']").val(number);
        }
      }
    }, 1000);

    $("input").bind("paste", function(e) {
      const input_name = self._init_field_name($(this));

      $("input[name='application[client_behavior_attributes][ctrl_v_" + input_name + "]']").val(1);

      const old_value = $(this).val();
      const past_value = e.originalEvent.clipboardData.getData('text');

      return setTimeout(() => {
        const new_value = $(this).val();

        const result = new_value.replace(past_value, '').replace(/\s+/g," ");

        const count = self._levDist(old_value, result);
        if (count <= 2) { return; }

        let number = Number($("input[name='application[client_behavior_attributes][number_of_critical_changes_" + input_name + "]']").val()) || 0;
        number += 1;

        return $("input[name='application[client_behavior_attributes][number_of_critical_changes_" + input_name + "]']").val(number);
      }
      , 0);
    });

    $("input").bind("copy", function(e) {
      const input_name = self._init_field_name($(this));

      return $("input[name='application[client_behavior_attributes][ctrl_c_" + input_name + "]']").val(1);
    });

    $("input").focusin(function() {
      const input_name = self._init_field_name($(this));

      self._start_interval(obj, input_name);
      return self._remember_old_value(obj, input_name, $(this).val());
    });

    $("input").focusout(function() {
      const input_name = self._init_field_name($(this));

      self._stop_interval(obj, input_name);
      return self._add_number_of_corrections(obj, input_name, $(this).val());
    });

    $("input").keydown(function(e) {
      const input_name = self._init_field_name($(this));
      const key1 = "keydown_" + input_name + "_value";
      const key2 = "keydown_" + input_name + "_enabled";
      const key3 = "keydown_" + input_name + "_worked";
      if (!obj[key2]) { obj[key2] = false; }
      if (!obj[key3]) { obj[key3] = false; }

      if ((e.keyCode === 8) || (e.keyCode === 46)) {
        if (obj[key2]) { return; }

        obj[key1] = $(this).val();
        obj[key2] = true;
        obj[key3] = false;
      } else {
        obj[key2] = false;
      }
    });

    $("input").keyup(function(e) {
      const input_name = self._init_field_name($(this));
      const key1 = "keydown_" + input_name + "_value";
      const key2 = "keydown_" + input_name + "_enabled";
      const key3 = "keydown_" + input_name + "_worked";

      if (!((e.keyCode === 8) || (e.keyCode === 46)) && !obj[key3]) {
        const new_value = $(this).val();

        const old_value = (obj[key1] || '').substring(0, new_value.length);

        if (new_value.length > old_value.length) { return; }

        const count = self._levDist(old_value, new_value);

        if (count <= 2) { return; }
        obj[key2] = false;
        obj[key3] = true;

        let number = Number($("input[name='application[client_behavior_attributes][number_of_critical_changes_" + input_name + "]']").val()) || 0;

        number += 1;

        return $("input[name='application[client_behavior_attributes][number_of_critical_changes_" + input_name + "]']").val(number);
      }
    });
  }

  _init_field_name(field) {
    const field_name = $(field).attr('name').match(/\[([^\]]*)\][^\]]*$/);
    if (field_name !== null) { return field_name.pop(); }
  }

  _start_interval(obj, input_name) {
    const key = "time_of_filling_" + input_name;
    const value = Number($("input[name='application[client_behavior_attributes][" + key + "]']").val());

    obj[key] = value || 0;

    return setInterval(
      () => obj[key] += 1,
      1000
    );
  }

  _stop_interval(obj, input_name) {
    const key = "time_of_filling_" + input_name;

    clearInterval(obj[key]);

    return $("input[name='application[client_behavior_attributes][" + key + "]']").val(obj[key]);
  }

  _add_number_of_corrections(obj, input_name, new_value) {
    let value = Number($("input[name='application[client_behavior_attributes][number_of_corrections_" + input_name + "]']").val()) || 0;

    const key = "old_" + input_name;
    if ((obj[key] === undefined) || (obj[key].length === 0) || (obj[key] === new_value)) { return; }

    value += 1;

    return $("input[name='application[client_behavior_attributes][number_of_corrections_" + input_name + "]']").val(value);
  }

  _remember_old_value(obj, input_name, old_value) {
    const key = "old_" + input_name;

    return obj[key] = old_value.toLowerCase().trim();
  }

  _levDist(s, t) {
    const d = [];
    //2d matrix
    // Step 1
    const n = s.length;
    const m = t.length;
    if (n === 0) {
      return m;
    }
    if (m === 0) {
      return n;
    }
    //Create an array of arrays in javascript (a descending loop is quicker)
    let i = n;
    while (i >= 0) {
      d[i] = [];
      i--;
    }
    // Step 2
    i = n;
    while (i >= 0) {
      d[i][0] = i;
      i--;
    }
    let j = m;
    while (j >= 0) {
      d[0][j] = j;
      j--;
    }
    // Step 3
    i = 1;
    while (i <= n) {
      const s_i = s.charAt(i - 1);
      // Step 4
      j = 1;
      while (j <= m) {
        //Check the jagged ld total so far
        if ((i === j) && (d[i][j] > 4)) {
          return n;
        }
        const t_j = t.charAt(j - 1);
        const cost = s_i === t_j ? 0 : 1;
        // Step 5
        //Calculate the minimum
        let mi = d[i - 1][j] + 1;
        const b = d[i][j - 1] + 1;
        const c = d[i - 1][j - 1] + cost;
        if (b < mi) {
          mi = b;
        }
        if (c < mi) {
          mi = c;
        }
        d[i][j] = mi;
        // Step 6
        //Damerau transposition
        if ((i > 1) && (j > 1) && (s_i === t.charAt(j - 2)) && (s.charAt(i - 2) === t_j)) {
          d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
        }
        j++;
      }
      i++;
    }
    // Step 7
    return d[n][m];
  }
}

export default DataBehaviorCollector;
