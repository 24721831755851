class FloatLabel {
  constructor(container) {
    this.container = container;
  }

  init() {
    for (var group of $(this.container).find('.form_float__group').not('.hidden')) {
      this.drapeInput($(group), $(group).find('input'));
      this.drapeInput($(group), $(group).find('select'));
    }
  }

  drapeInput($div, $input) {

    if ($input.hasClass('no-label') || $input.length === 0) return;

    this._active($div, $input)

    $input.on('focus', function() {
      if ($div.hasClass('radio_buttons')) return;

      $div.addClass('form_float__group-focused')
    })

    $input.on('blur', function() {
      if ($(this).val().length == 0) {
        $div.removeClass('form_float__group-focused')
      }
    })
  }

  _active($div, $input) {
    if ($input.val().length > 0)
      $div.addClass('form_float__group-active')
    else
      $div.removeClass('form_float__group-active')
  }
}

export default FloatLabel;

// class Desktop.Lib.FloatLabelInput
//   constructor: (@$group) ->
//     self = this

//     @$input = @$group.find('input')
//     @$select = @$group.find('select')

//     if @$input.length > 0
//       @_active(@$input)

//       @$input.off('focus').on 'focus', ->
//         self.$group.addClass('form_float__group-focused')
//         self._active($(@))

//       @$input.off('blur').on 'blur', ->
//         self.$group.removeClass('form_float__group-focused')
//         self._active($(@))

//     if @$select.length > 0
//       @_active(@$select)

//       @$select.on "select2:open", (e) ->
//         self.$group.addClass('form_float__group-focused')
//       @$select.on "select2:close", (e) ->
//         self.$group.removeClass('form_float__group-focused')
//       @$select.on "select2:select", (e) ->
//         self.$group.addClass('form_float__group-active')
//       @$select.on "select2:unselect", (e) ->
//         self.$group.removeClass('form_float__group-active')

//   _active: ($el) =>
//     if $el.val().length > 0
//       @$group.addClass('form_float__group-active')
//     else
//       @$group.removeClass('form_float__group-active')
